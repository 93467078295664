/*--------------------------------------------------------------------- File Name: responsive.css ---------------------------------------------------------------------*/


/*------------------------------------------------------------------- 991px x 768px ---------------------------------------------------------------------*/

@media (min-width: 1200px) and (max-width: 1342px) {
    #banner_main_md {
        display: none;
    }
    #myCarousel a.carousel-control-next {
        right: -3%;
    }
    #myCarousel a.carousel-control-prev {
        right: -3%;
    }
    .map {
        margin-right: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #banner_main_md {
        display: none;
    }
    .banner_slide {
        padding: 0 80px 0 0px;
    }
    .ban_track figure img {
        margin-top: 50%;
    }
    .transfot span {
        font-size: 15px;
    }
    .transfot h3 {
        font-size: 16px;
    }
    .banner_main {
        background-size: auto 64%;
        padding: 220px 0px 0px 0px;
    }
    .text-bg h1 {
        font-size: 43px;
        line-height: 52px;
    }
    .get_now {
        padding: 10px 10px 10px 10px;
        max-width: 100% !important;
    }
    .about {
        margin-top: -340px;
    }
    .yelldark_colo {
        margin: 0 30px;
    }
    .titlepage h2 {
        font-size: 48px;
    }
    .service_main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    #myCarousel a.carousel-control-next {
        right: -3%;
    }
    #myCarousel a.carousel-control-prev {
        right: -3%;
    }
    #veh a.carousel-control-next {
        right: 43%;
    }
    .map {
        margin-right: 0px;
    }
    .bottom_form .enter {
        width: 63%;
    }
    ul.location_icon li {
        font-size: 18px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #banner_main_lg {
        display: none;
    }
    #banner_main_md {
        display: block;
    }
    .text-bg span {
        padding: 0px 100px 0px 0px;
    }
    .text-bg h1 {
        font-size: 43px;
        padding-bottom: 20px;
        line-height: 44px;
    }
    .get_now {
        /*max-width: 100% !important;*/
        width: 120% !important;
    }
    .banner_main {
        background-position: 0%;
        background-size: auto 100% !important;
        background-repeat: no-repeat !important;
        padding: 250px 0px 0px 20px;
    }
    .about {
        margin-top: 90px;
    }
    .about .titlepage p {
        display: inline-block;
        color: #202121;
        font-size: 15px;
        line-height: 30px;
    }
    .transfot {
        margin-top: 40%;
        margin-left: 30%;
    }
    .transfot_form {
        width: 120% !important;
    }
    .transfot h3 {
        font-size: 18px;
    }
    #banner1 a.carousel-control-prev {
        top: 41%;
    }
    .titlepage h2 {
        font-size: 37px;
    }
    .service .titlepage p {
        display: inline-block;
        color: #202121;
        font-size: 16px;
        line-height: 30px;
    }
    .service_box {
        width: 13.3%;
    }
    .service_box i {
        width: 120px;
        height: 120px;
    }
    .service_box h4 {
        font-size: 12px;
    }
    #contact_img {
        display: flex;
        align-items: center;
    }
    #veh a.carousel-control-prev {
        left: 43%;
    }
    #veh a.carousel-control-next {
        right: 43%;
    }
    .choose_box h3 {
        font-weight: 380;
        font-size: 20px;
        font-family: "Oswald", sans-serif;
        font-optical-sizing: auto;
    }
    .choose_box p {
        display: inline-block;
        color: #202121;
        font-size: 15px;
        line-height: 30px;
        padding: 10px 0 70px 0;
    }
    ul.location_icon li {
        font-size: 14px;
        line-height: 20px;
    }
    .map {
        margin-right: 0px;
    }
    .bottom_form .enter {
        width: 100%;
        margin-bottom: 20px;
    }
    .banner_slide {
        padding: 0 80px 0 0px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .openbtn {
        display: none;
    }
    .openbtnMobile {
        display: block;
        padding-top: 50%;
    }
    #banner_main_md {
        display: none;
    }
    .ban_track {
        /*margin-top: 50px;*/
        width: 85%;
    }
    .ban_track img {
        margin-left: 15%;
    }
    .banner_main {
        padding: 152px 0px 0px 0px;
        background-color: #fff !important;
        background: inherit;
        min-height: 0px !important;
    }
    .banner_slide {
        padding: 0 80px 0 0px;
    }
    #quote-form {
        display: none !important;
    }
    .text-bg {
        max-width: inherit;
    }
    .transfot {
        padding-top: 30px;
    }
    .about {
        margin-top: 90px;
    }
    #myCarousel a.carousel-control-prev {
        top: 34%;
    }
    .text-bg h1 {
        font-size: 55px;
        line-height: 51px;
    }
    #banner1 a.carousel-control-prev {
        top: 44%;
    }
    #banner1 a.carousel-control-next {
        top: 50%;
    }
    .service {
        margin-top: 0px !important;
    }
    .service_box {
        width: 25.0%;
    }
    .service_box i {
        width: 156px;
        height: 156px;
    }
    .vehicles_truck {
        margin-bottom: 30px;
    }
    .blac_co {
        margin-top: 0px;
        margin-bottom: 30px;
    }
    #veh .carousel-control-prev,
    #veh .carousel-control-next {
        top: 105%;
    }
    #veh a.carousel-control-prev {
        left: 40%;
    }
    #veh .carousel-control-next {
        right: 40%;
    }
    .main_form {
        max-width: inherit;
    }
    .contact {
        padding-top: 30px !important;
    }
    .co_tru {
        padding-right: 0px;
        margin-top: 30px;
    }
    .linkedinicon {
        margin-right: 60px;
    }
    .map {
        margin-right: 0px;
        margin-top: 30px;
    }
    .choose {
        margin-top: 0px !important;
    }
    .choose_p_tag {
        text-align: center;
    }
    .choose_box {
        text-align: center;
    }
    .choose_box img {
        margin-left: 50%;
    }
    .choose_box {
        margin-bottom: 30px;
    }
    .about .titlepage {
        text-align: center !important;
    }
    .titlepage h2 {
        font-size: 31px;
    }
}

@media (max-width: 575px) {
    .banner_main {
        padding: 152px 0px 10px 0px;
        background-color: #fff !important;
        background: inherit;
    }
    .banner_slide {
        padding: 0 50px 0 0px;
    }
    #banner_main_md {
        display: none;
    }
    #banner_img {
        margin-left: 8%;
    }
    .header {
        padding: 30px 0px 15px 0px;
    }
    .logo {
        border: inherit;
        float: left;
        margin-top: 0px !important;
    }
    .text-bg {
        max-width: inherit;
        margin-top: 0px;
    }
    .transfot {
        padding-top: 30px;
    }
    .about {
        margin-top: 90px;
    }
    .text-bg h1 {
        font-size: 36px;
        line-height: 51px;
    }
    #banner1 a.carousel-control-prev {
        top: 44%;
    }
    #banner1 a.carousel-control-next {
        top: 50%;
    }
    .about .titlepage {
        text-align: center !important;
    }
    .about .titlepage p {
        color: #0c0202 !important;
        font-size: 16px !important;
        line-height: 30px !important;
        font-weight: 300 !important;
    }
    .titlepage h2 {
        font-size: 33px;
        line-height: 30px;
    }
    .titlepage p {
        color: #0c0202 !important;
        font-size: 16px !important;
        line-height: 30px !important;
        font-weight: 300 !important;
    }
    .testimonial_box h3 {
        font-size: 15px;
        line-height: 30px;
    }
    .service {
        margin-top: 0px !important;
    }
    .service_box {
        width: 100%;
    }
    .service_box h4 {
        font-weight: 380;
        font-size: 18px;
        font-family: "Oswald", sans-serif;
        font-optical-sizing: auto;
    }
    .service_main {
        justify-content: center;
    }
    .service_box i {
        margin: 0 auto;
    }
    .vehicles_truck {
        margin-bottom: 30px;
    }
    .blac_co {
        margin-top: 0px;
        margin-bottom: 30px;
    }
    #veh .carousel-control-prev,
    #veh .carousel-control-next {
        top: 105%;
    }
    #veh a.carousel-control-prev {
        left: 40%;
    }
    #veh .carousel-control-next {
        right: 40%;
    }
    .choose {
        margin-top: 0px !important;
    }
    .choose_p_tag {
        text-align: center;
    }
    .choose_box {
        text-align: center;
    }
    .choose_box img {
        margin-left: 8%;
    }
    .choose_box h3 {
        padding-top: 15px;
        font-weight: 500;
        font-size: 24px;
        font-family: "Oswald", sans-serif;
        font-optical-sizing: auto;
    }
    .choose_box p {
        color: #0c0202 !important;
        font-size: 16px !important;
        line-height: 30px !important;
        font-weight: 300 !important;
    }
    .main_form {
        max-width: inherit;
    }
    .co_tru {
        padding-right: 0px;
        margin-top: 30px;
    }
    .contact {
        padding-top: 30px !important;
    }
    .contactusimg {
        width: 100% !important;
    }
    .map {
        margin-right: 0px;
        margin-top: 30px;
    }
    .choose_box {
        display: flex;
        flex-direction: column;
        /*margin-bottom: 30px;*/
    }
    .right_bottun {
        margin-top: -100px;
    }
    .linkedinicon {
        margin-right: 60px;
    }
    ul.location_icon li {
        font-size: 19px;
        padding-bottom: 50px;
        padding-right: 50px;
    }
    .bottom_form .enter {
        width: 100%;
        margin-bottom: 20px;
    }
    .d_none {
        display: none;
    }
    .openbtn {
        display: none !important;
    }
    .openbtnMobile {
        display: block !important;
        background-color: transparent;
    }
    .text-bg h1 {
        padding-top: 45px !important;
    }
    #quote-form {
        display: none !important;
    }
    .banner_main {
        min-height: 900px !important;
    }
    .text-bg h1 {
        font-size: 50px !important;
    }
    .text-bg span {
        font-size: 18px !important;
    }
    .read_more {
        display: none !important;
    }
    .copyrighttext {
        font-size: 14px !important;
    }
}